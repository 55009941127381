.propertyTypeLeft{
    background-image: url('../../assets/images/land2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}
.propertyTypeRight{
    background-image: url('../../assets/images/page-1_img022.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.propertyTypeLeft1{
    background-image: url('../../assets/images/land12.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}
.propertyTypeRight1{
    background-image: url('../../assets/images/land3.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-dull{
    height: 380px;
    background-color: #00000091;
}
.content{
    padding: 125px 10px;
    color: white;
}


@media screen and (max-width:780px){
    .ServicesServices .d-flex, .SupportHome .d-flex{
        display: block !important;
    }
    .ServicesServices .content{
        padding: 100px 10px;
    }
    .w-50{
        width: 100% !important;
    }
    .propertyType .d-flex{
        display: block !important;
    }
    
}

