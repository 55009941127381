.main {
  color: white;
}
.item1,
.item2,
.item3 {
  position: relative; /* Ensure positioning context for the pseudo-element */
  background-position: center;
  padding-top: 230px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px !important;
}

.item1::before,
.item2::before,
.item3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: 1; /* Ensure the overlay appears above the background image */
}

.item1 {
  background-image: url("../../assets/images/main.png");
}

.item2 {
  background-image: url("../../assets/images/home.png");
}

.item2 ul li {
  padding: 5px 0px;
}

.item3 {
  background-image: url("../../assets/images/41e2f5cc-587b-4e25-b66d-048e7ed8a7e5.png");
}
.container {
  z-index: 4;
  position: relative;
}
.container a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 0px 20px;
}
.contact{
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
}
.contact h2:hover{
    color: #86b9f2;
    transition: ease-in-out;
}
.carousel-control-next {
  right: 50px;
}
.carousel-control-prev {
  right: 110px !important;
  left: unset;
}
.carousel-control-next,
.carousel-control-prev {
  top: 550px;
  bottom: 200px;
  padding: 14px;
  border: 2px solid white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.main-content h2 {
  font-size: 55px;
}
.main-content h3 {
  font-size: 40px;
}
.main-content a {
  padding: 10px 20px;
  border: 1px solid #152e44;
  background: #152e44;
  color: white;
  text-decoration: none;
  transition: 0.3s ease-out;
  display: inline-block;
}
.main-content a:hover {
  border: 1px solid white;
  background: transparent;
  transition: 0.3s ease-out;
}
.main-content ul {
  list-style-type: none;
  padding: 0;
}

/* Responsive */

@media screen and (max-width: 480px) {
  .item1,
  .item2,
  .item3 {
    padding-top: 80px;
    min-height: 630px !important;
  }
  .main-content {
    text-align: center !important;
  }
  .main-content h2 {
    font-size: 25px;
  }
  .main-content h3 {
    font-size: 20px;
  }

  .mainHome ul li {
    padding: 10px 0px;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  /* .main{
        margin-top:-100px ;
    } */
  .main-content h2 {
    font-size: 30px;
  }
  .w-75 {
    width: 100% !important;
  }
  .main-content h3 {
    font-size: 20px;
  }
  .item1,
  .item2,
  .item3 {
    min-height: 650px !important;
    background-position: center;
    padding-top: 100px;
  }
}

@media screen and (max-width: 990px) and (min-width: 769px) {
  .main {
    margin-top: 0px;
  }
}
