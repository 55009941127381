.testimonal .carousel-control-prev {
  
    top: 100px !important;
    padding: 14px;
    border: 2px solid #86B9F2;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
.testimonal .carousel-control-next {

    top: 100px !important;
  padding: 14px;
    border: 2px solid #86B9F2;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
.testimonal .carousel-control-next-icon {
  background-image: url(../../assets/images/arrow_move_right_next_navigation_icon_192501.png);
}
.testimonal .carousel-control-prev-icon {
  background-image: url(../../assets/images/arrow_left_back_navigation_icon_192498.png);
}
.border-radius-50 img {
  border-radius: 50%;
}

@media screen and (max-width: 480px) {
  .testimonal .carousel-control-prev {
    left: 20px !important;
    top: 20px !important;
  }
  .testimonal .carousel-control-next {
    top: 20px !important;
    right: 0px;
  }
  .btn-custom {
    margin-right: 5px;
  }
  .testimonal .d-flex {
    display: block !important;
    text-align: center;
  }
  .testimonal .w-50 {
    width: 100% !important;
  }
  .carousel-indicators {
    display: none !important;
  }
  .border-radius-50 img {
    width: 100px;
    height: 100px;
  }
  .w-75{
    width: 100% !important;
  }
  
}

@media screen and (max-width:768px) and (min-width:481px) {
  .testimonal .carousel-control-prev {
    left: 20px !important;
    top: 50px !important;
  }
  .testimonal .carousel-control-next {
    top: 50px !important;
    right: 5px;
  }
  .testimonal .d-flex {
    display: block !important;
    text-align: center;
  }
  .testimonal .w-50 {
    width: 100% !important;
  }
}
@media screen and (max-width:990px) and (min-width:769px) {
  .testimonal .carousel-control-prev {
    left: 20px !important;
    top: 50px !important;
  }
  .testimonal .carousel-control-next {
    top: 50px !important;
  }
  .testimonal .d-flex {
    display: block !important;
    text-align: center;
  }
  .testimonal .w-50 {
    width: 100% !important;
  }
}

@media screen and (max-width:1200px) and (min-width:991px) {
  .testimonal .carousel-control-prev {
    right: 50% !important;
    top: 100px !important;
  }
  .testimonal .carousel-control-next {
    top: 100px !important;
    left: 50%;
  }

}