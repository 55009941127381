.ContactUsForm {
  background: #86b9f2;
  color: white;
  padding: 50px;
  margin: 70px auto;
}
.form-control {
  padding: 15px;
  border-radius: 0px;
}
.SendButton span {
  display: inline-block;
  margin: 20px 0px;
  padding: 20px 40px;
  border: 1px solid #152e44;
  background: #152e44;
  color: white;
  text-decoration: none;
  transition: 0.3s ease-out;
}
.btn:hover {
  display: inline-block;
  /* margin: 20px 0px; */
  padding: 2px 4px;
  border: none;
  /* background: #152e44; */
  /* color: white; */
  text-decoration: none;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .ContactUsForm .row {
    display: block;
  }
  .form-control {
    margin: 15px 0px;
  }
  .ContactUsForm {
    padding: 25px;
  }
  .cantactUsContainer {
    padding: 0px 14px;
  }
}
