.contactUs{
    padding-top: 200px;
}
ul{
    list-style: none;
    padding: 0px; 
}
.contactUs ul li{
    padding: 15px 0px;

}
.contactUs ul a{
    text-decoration: none;
    color: black; 
}
.contactUs .footer-icons{
    color: black !important;
}

@media screen and (max-width:768px) {
    .contactUs{
        padding-top: 100px;
    }
    .contactUs h3{
        text-align: center;
    }
    .contactUs ul li{
        text-align: center;
    }
    .contactUs .row{
        display: block;
        
    }
}