.navbar-top-contact {
  padding: 10px 0px;
  text-align: end;
  color: white;
  transition: all 0.3s ease-in-out;
  visibility: visible;
}
.fixed {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1999;
}
.offCanvas {
  display: none;
}
.hidden {
  height: 0px;
  transition: all 0.3s ease-in-out;
}
.navbar-top-contact .footer-icons {
  color: white !important;
}
.navbar-top-contact a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 0px 20px;
}
.navbar {
  padding: 10px;
  box-shadow: 0 0 4px 0px #b5b5b5;
  background-color: white !important;
  position: sticky;
  top: 0px;
}
.navbar ul li {
  padding: 0px;
}
.navbar a {
  font-size: 20px;
  font-weight: 700;
  color: #152e44;
}
.navbar .ms-3 {
  border-left: 2px solid grey;
}
.navbar a:hover,
.navbar a:active,
.navbar a:focus {
  color: #86b9f2;
}

@media screen and (max-width: 780px) {
  .fixed {
    display: none;
  }
  .offCanvas {
    display: block;
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    box-shadow: 0 0 13px -1px rgb(0 0 0 / 17%);
    background: #152e44;
    z-index: 9;
  }
  .offcanvas-header {
    display: block;
    padding: 50px 0px;
  }
  .offcanvas-header .text-reset {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
  }
  .offCanvasnav {
    font-size: 35px;
    padding: 3px 0px;
    color: white;
  }
  #offcanvasExample {
    background-color: #152e44;
    color: white;
    width: 80%;
  }
  #offcanvasExampleContact{
    height: 60px;
    background-color: #152e44;
    color: white;
    top: 0px;
  }
  #offcanvasExampleContact .text-reset{
    font-size: 35px;
    padding: 3px 10px;
    color: white;
}
  .offcanvas-end a{
    color: white;
    text-decoration: none;
  }
  .offcanvas.offcanvas-start {
    top: 58px;
  }
  .offCanvas .nav-link{
    padding: 5px 10px;
  }
  .ms-3 {
    margin-left: 0rem !important;
  }
  .nav-item {
    text-align: left;
  }
  
  .activeNav{
    color: #fff;
    background: #86b9f2;
  }
}
