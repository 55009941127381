.card {
    margin-top: 30px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
.card a{
    text-decoration: none;
    color: black;
}
.card-icon {
  color: #6a6add;
  padding: 14px;
  margin: auto;
  width: 200px;
}


@media screen and (max-width:480px){
  
    
}