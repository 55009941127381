.ServicesContent{
    padding-top: 220px;
}
@media screen and (max-width:480px) {
    .ServicesContent .py-5{
        padding: 0px !important;
    }
    .pt-5 {
        padding-top: 1rem!important;
    }
    .ServicesContent{
        padding-top: 100px;
    }
}

@media screen and (max-width:768px) and (min-width:481px) {
    .pt-5 {
        padding-top: 1rem!important;
    }
    .ServicesContent .py-5{
        padding: 0px !important;
    }
    .ServicesContent{
        padding-top: 100px;
    }
}
