.CompanyContent{
    padding-top: 220px;
}

@media screen and (max-width:780px) {
    /* .responsive{
        
            padding-top: 150px !important;
        
    } */
    .CompanyContent {
        padding-top: 100px;
    }
    
}

