.footerUpper{
    background-color: #152E44;
    color: white;
    text-align: left;
}
.footerUpper ul{
    list-style: none;
    padding: 0px;
    
}
.footerUpper ul a{
    text-decoration: none;    
    color: white;
}
.footerUpper ul li{
    margin: 0px;
    padding: 10px 0px;  
}
.footer-icons{
    height: 40px;
    width: 40px;
    border: 2px solid #86b9f2;
    padding: 7px;
    border-radius: 50%;
    margin-right: 8px;
    color: white !important;
}
.footerLower{
    background-color: #112536;
    color: white;
}
.footerLower a{
    text-decoration: none;
}
.footer a:hover{
    color: white;
}
.Social a{
    padding: 10px 20px;
    border: 2px solid #86b9f2;
    background: #152e44;
    color: white;
    text-decoration: none;
    transition: .3s ease-out;
}
.Social a:hover{
    border: 2px solid #86b9f2;
    background: #376282;
    transition: .3s ease-out;
}


@media screen and (max-width:480px) {
    .p-5 {
        padding: 1rem!important;
    }
    .footerUpper ul li {
        margin: 0px 0px;
    }
    .footerUpper{
        text-align: center;
    }
    .footer ul li {
        padding: 10px 0px;
    }
    .footerLower{
        font-size: 13px;
    }
    .footer .d-flex{
        display: block !important;
    }
    .footer-icons{
        display: block;
        margin: 5px auto;
    }
    
}